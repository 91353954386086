import { easeCubicInOut, interpolateArray, median, select } from 'd3';
import React, { useLayoutEffect, useMemo, useRef } from 'react';

import { LineStyle } from './MicroChart';
import { useMicroChartContext } from './MicroChartContext';
import { MicroChartSeriesProps } from './MicroChartSeries';

export const MicroChartLineSeries: React.FC<MicroChartSeriesProps> = ({
  data,
  color,
  lineWidth,
  lineOpacity,
  lineStyle,
  animationDuration,
  animationEasing = animationDuration ? easeCubicInOut : undefined,
}) => {
  const { lineGenerator } = useMicroChartContext();
  const lineRef = useRef<SVGPathElement>(null);
  const animated = animationDuration && animationEasing;

  const medianValue = useMemo(() => median(data) ?? 0, [data]);
  const medianData = useMemo(
    () => data.map((value) => (typeof value === 'number' ? medianValue : null)),
    [data, medianValue],
  );

  useLayoutEffect(() => {
    if (!animated) {
      return;
    }

    const line = select(lineRef.current);
    const interpolator = interpolateArray(medianData, data);

    line
      .transition()
      .duration(animationDuration)
      .ease(animationEasing)
      .tween('path', () => (t: number) => {
        line.datum(interpolator(t)).attr('d', lineGenerator);
      });
  }, [lineRef, data, medianData, lineGenerator, animated, animationDuration, animationEasing]);

  return (
    <path
      ref={lineRef}
      stroke={color}
      strokeWidth={lineWidth}
      strokeOpacity={lineOpacity}
      strokeDasharray={lineStyle === LineStyle.Dashed ? 4 : 0}
      fill="none"
      d={lineGenerator(animated ? medianData : data) ?? ''}
      vectorEffect="non-scaling-stroke"
    />
  );
};
