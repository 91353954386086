import { snakeCase, trim } from 'lodash-es';

const getCleanPath = (path: string) => {
  // Remove leading and trailing slashes
  const cleanPath = trim(path, '/');

  // Check if the path starts with "web/" and remove it if present
  return cleanPath.startsWith('web/') ? cleanPath.slice(4) : cleanPath;
};

export const getPathDetails = (
  path: string,
): {
  moduleName?: string;
  pageName?: string;
  focusArea?: string;
  focusAreaPath?: string;
} => {
  let cleanPath = getCleanPath(path);

  // Destructure to get moduleName, pageName, and focusArea (if available)
  let [moduleName, pageName, focusArea] = cleanPath.split('/').filter(Boolean); // Split the path and remove empty parts

  if (!focusArea || focusArea === 'undefined') {
    const urlParams = new URLSearchParams(window.location.search);
    const embeddedFocusAreaPath = urlParams.get('embeddedFocusAreaPath');

    if (embeddedFocusAreaPath) {
      cleanPath = getCleanPath(embeddedFocusAreaPath);
      [moduleName, pageName, focusArea] = cleanPath.split('/').filter(Boolean);
    }
  }

  return {
    moduleName: moduleName || undefined,
    pageName: pageName || undefined,
    focusArea: focusArea ? snakeCase(focusArea) : undefined,
    focusAreaPath: cleanPath.length > 0 ? cleanPath : undefined,
  };
};
