import React from 'react';

export interface GlowFilterProps {
  id: string;
  size: number;
  strokeWidth: number;
  dilateRadius?: number;
  blurSpread?: number;
}

const GlowFilter: React.FC<GlowFilterProps> = ({
  id,
  size,
  strokeWidth,
  dilateRadius = strokeWidth / 2,
  blurSpread = strokeWidth * 2.5,
}) => {
  const padding = dilateRadius + blurSpread * 3;
  const filterSize = size + 2 * padding;

  return (
    <defs>
      <filter
        id={id}
        x={-padding}
        y={-padding}
        width={filterSize}
        height={filterSize}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="noFill" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="alphaMask"
        />
        <feMorphology
          in="alphaMask"
          operator="dilate"
          radius={dilateRadius}
          result="expandedAlpha"
        />
        <feOffset in="expandedAlpha" result="offsetAlpha" />
        <feGaussianBlur in="offsetAlpha" stdDeviation={blurSpread} result="blurredAlpha" />
        <feComposite in="blurredAlpha" in2="alphaMask" operator="out" result="glowShape" />
        <feColorMatrix
          in="glowShape"
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.6 0 0 0 0 0.2 0 0 0 0.25 0"
          result="coloredGlow"
        />
        <feBlend mode="normal" in="coloredGlow" in2="noFill" result="finalGlow" />
        <feBlend mode="normal" in="SourceGraphic" in2="finalGlow" result="combinedOutput" />
      </filter>
    </defs>
  );
};

export default GlowFilter;
