import { useAnimation } from 'framer-motion';
import { useEffect } from 'react';

export interface UseCircularProgressBarAnimationProps {
  value: number;
}

const useCircularProgressBarAnimation = ({ value }: UseCircularProgressBarAnimationProps) => {
  // Animation controls for path length and rotation
  const pathLengthControls = useAnimation();
  const rotationControls = useAnimation();

  // Update animations when the value changes
  useEffect(() => {
    pathLengthControls.start({ pathLength: value });
    rotationControls.start({ rotate: value * 360 });
  }, [value, pathLengthControls, rotationControls]);

  return { pathLengthControls, rotationControls };
};

export default useCircularProgressBarAnimation;
