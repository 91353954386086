import { easeCubicInOut, interpolateArray, median, select } from 'd3';
import React, { useEffect, useId, useMemo, useRef } from 'react';

import { useMicroChartContext } from './MicroChartContext';
import { MicroChartSeriesProps } from './MicroChartSeries';

export const MicroChartAreaSeries: React.FC<MicroChartSeriesProps> = ({
  data,
  color,
  lineWidth,
  lineOpacity,
  fillOpacity,
  animationDuration,
  animationEasing = animationDuration ? easeCubicInOut : undefined,
  gradientHeight = '100%',
}) => {
  const id = useId();
  const gradientId = useMemo(() => `${id}.gradient`, [id]);
  const { areaGenerator, lineGenerator } = useMicroChartContext();
  const lineRef = useRef<SVGPathElement>(null);
  const areaRef = useRef<SVGPathElement>(null);
  const animated = animationDuration && animationEasing;

  const medianValue = useMemo(() => median(data) ?? 0, [data]);
  const medianData = useMemo(
    () => data.map((value) => (typeof value === 'number' ? medianValue : null)),
    [data, medianValue],
  );

  useEffect(() => {
    if (!animated) {
      return;
    }

    const line = select(lineRef.current);
    const area = select(areaRef.current);
    const interpolator = interpolateArray(medianData, data);

    line
      .transition()
      .duration(animationDuration)
      .ease(animationEasing)
      .tween('path', () => (t: number) => {
        line.datum(interpolator(t)).attr('d', lineGenerator);
      });

    area
      .transition()
      .duration(animationDuration)
      .ease(animationEasing)
      .tween('path', () => (t: number) => {
        area.datum(interpolator(t)).attr('d', areaGenerator);
      });
  }, [
    lineRef,
    areaRef,
    data,
    medianData,
    lineGenerator,
    areaGenerator,
    animated,
    animationDuration,
    animationEasing,
  ]);

  return (
    <g>
      <defs>
        <linearGradient id={gradientId} x1="0%" x2="0%" y1="0%" y2={gradientHeight}>
          <stop offset="0%" stopOpacity="1" stopColor={color} />
          <stop offset="100%" stopOpacity="0" stopColor={color} />
        </linearGradient>
      </defs>
      <path
        ref={lineRef}
        stroke={color}
        strokeWidth={lineWidth}
        strokeOpacity={lineOpacity}
        fill="none"
        d={lineGenerator(animated ? medianData : data) ?? ''}
        vectorEffect="non-scaling-stroke"
      />
      <path
        ref={areaRef}
        stroke="none"
        fill={`url(#${gradientId})`}
        fillOpacity={fillOpacity}
        d={areaGenerator(animated ? medianData : data) ?? ''}
        vectorEffect="non-scaling-stroke"
      />
    </g>
  );
};
