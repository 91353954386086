import { FilterOption, isSortableFilterOption, isTreeFilterOptionResult } from './types';

/**
 * Determines the sublabel for a FilterOption based on its specific type.
 *
 * Depending on the type of the provided filter option, this function returns:
 * SortableFilterOption - the value of the sub-label field.
 * TreeFilterOptionResult - a joined string of parent node names.
 * Other filter types - the first additional label.
 *
 * @param filterOption - The filter option whose type determines the sublabel.
 * @param search - The current search.
 * @param delimiter - Delimiter used for joining parent node names (default: ' • ').
 * @returns The sublabel derived from the filter option's type and attributes.
 */
export function resolveFilterOptionSublabel(
  filterOption: FilterOption,
  search?: string,
  delimiter = ' • ',
): string {
  // Handle SortableFilterOption type.
  if (isSortableFilterOption(filterOption)) {
    return filterOption.subLabel;
  }

  // Handle the TreeFilterOptionResult type.
  if (isTreeFilterOptionResult(filterOption)) {
    const parentNodeNames = filterOption.parentNodeNames;

    if (!search || !parentNodeNames?.length) {
      return filterOption.additionalLabels?.[0] ?? '';
    }

    return parentNodeNames.join(delimiter);
  }

  return filterOption.additionalLabels?.[0] ?? '';
}
