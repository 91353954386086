import { useAnalyticsTrackEvent } from '@aily/analytics-service';
import {
  useRevertAgentDecisionByUserMutation,
  useTakeAgentDecisionByUserMutation,
} from '@aily/graphql-sdk/modules/pro';
import * as T from '@aily/graphql-sdk/schema';
import { EditIcon, Heading, VStack } from '@aily-labs/ui';
import { Box, Button, Stack, Typography } from '@mui/material';
import { FC, memo, useCallback, useState } from 'react';

import { useProFiltersContext } from '../../../../contexts';
import { TriangleDown, TriangleUp } from '../../../../icons';
import { useModule } from '../../../../providers';
import { colors } from '../../../../theme/default/colors';
import { mapSentimentToColor } from '../../../../utils';
import highEndScenarioNike from '../../assets/popup_high_end_scenario_nike.png';
import longTermOptimisationAmlitelimab from '../../assets/popup_long_term_optimisation_Amlitelimab.png';
import longTermOptimisationScreen1 from '../../assets/popup_long_term_optimisation_screen1.png';
import longTermOptimisationScreen2 from '../../assets/popup_long_term_optimisation_screen2.png';
import midTermOptimisationAmlitelimab from '../../assets/popup_mid_term_optimisation_Amlitelimab.png';
import midTermOptimisationScreen1 from '../../assets/popup_mid_term_optimisation_screen1.png';
import midTermOptimisationScreen2 from '../../assets/popup_mid_term_optimisation_screen2.png';
import minimiseCostScenarioNike from '../../assets/popup_minimise_cost_scenario_nike.png';
import optimisedForCostsAmlitelimab from '../../assets/popup_optimised_for_costs_Amlitelimab.png';
import optimisedForCostsAmlitelimabFourth from '../../assets/popup_optimised_for_costs_Amlitelimab_fourth.png';
import optimisedForCostsAmlitelimabThird from '../../assets/popup_optimised_for_costs_Amlitelimab_third.png';
import optimisedForCosts from '../../assets/popup_optimised_for_costs@2x.png';
import optimisedForSpeedAmlitelimab from '../../assets/popup_optimised_for_speed_Amlitelimab.png';
import optimisedForSpeedAmlitelimabFourth from '../../assets/popup_optimised_for_speed_Amlitelimab_fourth.png';
import optimisedForSpeedAmlitelimabThird from '../../assets/popup_optimised_for_speed_Amlitelimab_third.png';
import optimisedForSpeed from '../../assets/popup_optimised_for_speed@2x.png';
import optimisedForValueAmlitelimab from '../../assets/popup_optimised_for_value_Amlitelimab.png';
import optimisedForValueAmlitelimabFourth from '../../assets/popup_optimised_for_value_Amlitelimab_fourth.png';
import optimisedForValueAmlitelimabSecond from '../../assets/popup_optimised_for_value_Amlitelimab_second.png';
import optimisedForValueAmlitelimabThird from '../../assets/popup_optimised_for_value_Amlitelimab_third.png';
import optimisedForValue from '../../assets/popup_optimised_for_value@2x.png';
import optimisedScenarioNike from '../../assets/popup_optimised_scenario_nike.png';
import shortTermOptimisationAmlitelimab from '../../assets/popup_short_term_optimisation_Amlitelimab.png';
import shortTermOptimisationScreen1 from '../../assets/popup_short_term_optimisation_screen1.png';
import shortTermOptimisationScreen2 from '../../assets/popup_short_term_optimisation_screen2.png';
import resourceAllocationAmlitelimab from '../../assets/resource_allocation_Amlitelimab.png';
import { ArrowIndicatorProps, LeaderboardProps } from '../../types';
import {
  AgentJsonData,
  Element,
  ElementType,
  ProgressBarElement,
  Screen,
} from '../../types/agentJsonData';
import { mapLeaderboardItemSentimentToSentiment } from '../../utils';
import { PopupChart } from '../AgentChart/PopupChart';
import AgentOutro from '../AgentOutro/AgentOutro';
import { AgentProgressBar } from '../AgentProgressBar/AgentProgressBar';
import AgentTextElement from '../AgentScreenItems/AgentTextElement';

const assetsMap: Record<string, string> = {
  shortTermOptimisationAmlitelimab: shortTermOptimisationAmlitelimab,
  midTermOptimisationAmlitelimab: midTermOptimisationAmlitelimab,
  longTermOptimisationAmlitelimab: longTermOptimisationAmlitelimab,
  optimisedForValueAmlitelimab: optimisedForValueAmlitelimab,
  optimisedForValueAmlitelimabSecond: optimisedForValueAmlitelimabSecond,
  optimisedForValueAmlitelimabThird: optimisedForValueAmlitelimabThird,
  optimisedForValueAmlitelimabFourth: optimisedForValueAmlitelimabFourth,
  optimisedForSpeedAmlitelimab: optimisedForSpeedAmlitelimab,
  optimisedForSpeedAmlitelimabThird: optimisedForSpeedAmlitelimabThird,
  optimisedForSpeedAmlitelimabFourth: optimisedForSpeedAmlitelimabFourth,
  optimisedForCostsAmlitelimab: optimisedForCostsAmlitelimab,
  optimisedForCostsAmlitelimabThird: optimisedForCostsAmlitelimabThird,
  optimisedForCostsAmlitelimabFourth: optimisedForCostsAmlitelimabFourth,
  resourceAllocationAmlitelimab: resourceAllocationAmlitelimab,

  optimisedForValue: optimisedForValue,
  optimisedForSpeed: optimisedForSpeed,
  optimisedForCosts: optimisedForCosts,

  optimisedScenarioNike: optimisedScenarioNike,
  minimiseCostScenarioNike: minimiseCostScenarioNike,
  highEndScenarioNike: highEndScenarioNike,
  shortTermOptimisationScreen1: shortTermOptimisationScreen1,
  shortTermOptimisationScreen2: shortTermOptimisationScreen2,
  midTermOptimisationScreen1: midTermOptimisationScreen1,
  midTermOptimisationScreen2: midTermOptimisationScreen2,
  longTermOptimisationScreen1: longTermOptimisationScreen1,
  longTermOptimisationScreen2: longTermOptimisationScreen2,
};

type Props = {
  element: Element;
  titleRef?: React.Ref<HTMLElement>;
  decision: T.StoryDecision | null;
  agentJsonData: AgentJsonData;
  screen: Screen;
  chartHeight?: number;
  chartWidth?: number;
  textElementStyle?: React.CSSProperties;
  onModalClose?: () => void;
  onAgentClose?: () => void;
  onBackdropClose?: (forceRefetch: boolean) => void;
  onDecide?: () => void;
};

export const AgentElementRenderer = ({
  element,
  titleRef,
  decision,
  agentJsonData,
  screen,
  chartHeight,
  chartWidth,
  textElementStyle,
  onModalClose,
  onAgentClose,
  onBackdropClose,
  onDecide,
}: Props) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [confirmationText, setConfirmationText] = useState('');
  const currentModule = useModule();
  const proFiltersContext = useProFiltersContext();
  const [takeDecision] = useTakeAgentDecisionByUserMutation();
  const [revertDecision] = useRevertAgentDecisionByUserMutation();
  const trackEvent = useAnalyticsTrackEvent();
  const handleRevertDecision = useCallback(async () => {
    await revertDecision({
      variables: {
        input: { agentId: agentJsonData.id },
      },
    });

    onDecide?.();

    onAgentClose?.();
    onBackdropClose?.(true);
  }, [agentJsonData.id, onAgentClose, onBackdropClose]);

  const handleTakeDecision = useCallback(async () => {
    const agentInfo = agentJsonData?.imp_agent
      ? agentJsonData?.imp_agent
      : agentJsonData?.opt_agent;
    await takeDecision({
      variables: {
        input: {
          agentId: agentJsonData.id,
          moduleCode: currentModule?.moduleCode ?? '',
          screenIds: screen.scenario_screen_ids,
          titleLabel: screen.title_card ?? '',
          subtitle: !isNaN(screen.card_value) ? String(screen.card_value) : '',
          decisionType: T.FeedDecisionType.Agent,
        },
      },
    });

    onDecide?.();

    trackEvent('agent.clicked', {
      name: agentInfo?.name ?? '',
      component: 'agent',
      component_id: agentJsonData.id,
      intent: 'click',
      item_type: 'button',
      event_version: '2.1.0',
      component_value: 'lets_do_it',
      screen_id: screen.scenario_screen_ids.toString(),
      filter_name: proFiltersContext?.selectedFilters.map((filter) => filter.label),
    });
    setConfirmationText('');
    setIsConfirmationOpen(false);
    onBackdropClose?.(true);
    onAgentClose?.();
  }, [
    agentJsonData,
    currentModule,
    screen,
    onModalClose,
    onAgentClose,
    onBackdropClose,
    setConfirmationText,
    setIsConfirmationOpen,
  ]);

  const openConfirmationModal = useCallback(
    (confirmationText: string) => {
      setConfirmationText(confirmationText);
      setIsConfirmationOpen(true);
    },
    [setConfirmationText, setIsConfirmationOpen],
  );

  const handleCloseConfirmationModal = useCallback(() => {
    setConfirmationText('');
    setIsConfirmationOpen(false);
  }, [setConfirmationText, setIsConfirmationOpen]);

  const handleTakeDecisionClick = useCallback(
    (confirmationText: string) => {
      // if the take decision btn has confirmation text, then toggle the modal
      // this check is mandatory for backwards compatibility with agents that don't have the confirmation button
      if (confirmationText) {
        openConfirmationModal(confirmationText);
      } else {
        handleTakeDecision();
      }
    },
    [openConfirmationModal, handleTakeDecision],
  );

  const ArrowIndicatorIcon: FC<ArrowIndicatorProps> = memo(({ sentiment }) => {
    switch (sentiment) {
      case T.Sentiment.Positive:
        return <TriangleUp color="success" sx={{ fontSize: 16 }} />;
      case T.Sentiment.Negative:
        return <TriangleDown color="error" sx={{ fontSize: 16 }} />;
      default:
        return null;
    }
  });

  const renderLeaderboard = useCallback(
    ({ id, content, description, variant }: LeaderboardProps) => {
      return (
        <Stack className={`leaderboard ${variant}`} id={id}>
          {description && <Typography>{description}</Typography>}
          <Stack flexDirection="column">
            {content.map((item, index) => {
              const itemSentiment = mapLeaderboardItemSentimentToSentiment(
                item.formatted_value.sentiment,
              );
              return (
                <Stack
                  key={index}
                  sx={{
                    borderBottom: '2px solid rgba(255, 255, 255, 0.16)',
                    paddingBottom: '10px',
                    paddingTop: '10px',
                    paddingLeft: '0px',
                  }}
                >
                  <Stack
                    flexDirection="row"
                    width="100%"
                    gap="10px"
                    key={index}
                    alignItems="flex-start"
                    justifyContent="space-between"
                    className={`leaderboard-item ${item.sentiment}`}
                  >
                    <Typography variant="h7" sx={{ minWidth: 'fit-content' }} color="grey">
                      #{item.rank}
                    </Typography>
                    <Stack flexDirection="column" justifyContent="space-between" width="100%">
                      <Stack flexDirection="row">
                        <Typography variant="h7" sx={{ flexGrow: 1 }}>
                          {item.title}
                        </Typography>
                        <Stack
                          flexDirection="row"
                          justifyContent="center"
                          alignItems="center"
                          gap={'4px'}
                        >
                          <Typography variant="h9" color={mapSentimentToColor(itemSentiment)}>
                            {item.formatted_value.text}
                          </Typography>
                          <ArrowIndicatorIcon
                            sentiment={item.formatted_value.sentiment.toUpperCase() as T.Sentiment}
                          />
                        </Stack>
                      </Stack>
                      <Typography variant="bodyBold" color={mapSentimentToColor(itemSentiment)}>
                        {item.subtitle}
                      </Typography>
                      <Typography color="white">{item.body}</Typography>
                    </Stack>
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      );
    },
    [],
  );

  const renderProgressBar = useCallback(
    (element: ProgressBarElement) => {
      return (
        <VStack flex={1} width="100%">
          <Typography variant="h5" ref={titleRef} paddingBottom={1}>
            {element.title}
          </Typography>
          {element.content &&
            element.content.map(({ name, data }, index) => (
              <Box paddingBottom="16px" key={index}>
                <Heading type="H8" color="$white">
                  {name}
                </Heading>
                {data.map(
                  (
                    { description, value, sentiment, formatted_value, formatted_value2, star },
                    index,
                  ) => (
                    <AgentProgressBar
                      key={index}
                      value={value}
                      formattedValue={formatted_value}
                      formattedValue2={formatted_value2}
                      max={element.max_value}
                      description={description}
                      inverted
                      sentiment={sentiment as T.Sentiment}
                      barBackground={mapSentimentToColor(sentiment.toUpperCase() as T.Sentiment)}
                      star={star}
                    />
                  ),
                )}
              </Box>
            ))}
        </VStack>
      );
    },
    [titleRef],
  );

  return (
    <>
      <AgentOutro
        isOpen={isConfirmationOpen}
        textContent={confirmationText}
        onCloseConfirmationModal={handleCloseConfirmationModal}
        onAcceptDecision={handleTakeDecision}
      />

      {element.type === ElementType.Text && (
        <AgentTextElement
          data-testid="agent-test-element"
          element={element}
          style={textElementStyle}
        />
      )}

      {element.type === ElementType.Images && (
        <VStack>
          <img
            src={assetsMap[element.asset]}
            style={{
              width: element.width,
              height: element.height,
            }}
          />
        </VStack>
      )}

      {element.type === ElementType.ProgressBar && renderProgressBar(element as ProgressBarElement)}

      {element.type === ElementType.Chart && (
        <PopupChart data={element.content} height={chartHeight} width={chartWidth} />
      )}

      {element.type === ElementType.LeaderBoard && renderLeaderboard(element as LeaderboardProps)}

      {element.type === ElementType.DecisionButton && (
        <Box paddingTop={1} paddingBottom={1} alignSelf="center">
          {decision ? (
            <Button
              data-testid="test-revert-decision"
              onClick={() => handleRevertDecision()}
              sx={{
                background: colors.neutrals.grey1,
                borderRadius: 12,
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              <EditIcon color={colors.neutrals.white} />
              <Typography
                variant="smallBold"
                fontWeight={860}
                lineHeight="21.6px"
                paddingLeft={2}
                color={colors.neutrals.white}
              >
                REVISIT DECISION
              </Typography>
            </Button>
          ) : (
            <Button
              onClick={() => handleTakeDecisionClick(element.outro_body)}
              data-testid="test-take-decision"
              sx={{
                background: colors.neutrals.white,
                color: colors.neutrals.black,
                borderRadius: 12,
                paddingLeft: 2,
                paddingRight: 2,
                minWidth: 159,
                height: 40,
              }}
            >
              {element.content}
            </Button>
          )}
        </Box>
      )}

      {element.type === ElementType.SubtitleText && (
        <Typography
          sx={{
            fontWeight: 510,
            fontSize: 16,
            lineHeight: '19px',
            color: colors.neutrals.grey4,
            marginBottom: 2,
          }}
          textAlign={element?.alignment}
        >
          {element?.content}
        </Typography>
      )}

      {element.type === ElementType.HorizontalLine && (
        <Box sx={{ borderBottom: '2px solid rgba(255, 255, 255, 0.16)' }} />
      )}
    </>
  );
};
