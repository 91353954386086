import { TableColumnOptionsResult, TableColumnResult } from '@aily/graphql-sdk/schema';
import { filter, find, unionBy } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';

interface SelectGroupState {
  selectGroupId: TableColumnOptionsResult['selectGroupId'];
  selectDisplayGroupId: TableColumnOptionsResult['selectDisplayGroupId'];
  dataKey: TableColumnResult['dataKey'];
}

export function useColumnSelect(columns?: TableColumnResult[] | null) {
  const [selectedColumns, setSelectedColumns] = useState<SelectGroupState[]>([]);

  /**
   * Column is selectable if there are multiple columns with the same `selectGroupId`
   */
  const isColumnSelectable = useCallback<(column: TableColumnResult) => boolean>(
    ({ options }) => {
      if (options?.selectGroupId) {
        const { selectGroupId } = options;
        return filter(columns, { options: { selectGroupId } })?.length > 1;
      }

      if (options?.selectDisplayGroupId) {
        const { selectDisplayGroupId } = options;
        return filter(columns, { options: { selectDisplayGroupId } })?.length > 1;
      }

      return false;
    },
    [columns],
  );

  /**
   * Column is selected if it has a selected state or if it is selected by default
   */
  const isColumnSelected = useCallback<(column: TableColumnResult) => boolean>(
    ({ dataKey, options }) => {
      if (options?.selectGroupId) {
        const { selectGroupId, selectDisplayGroupId } = options;
        const selectState = find(selectedColumns, { selectGroupId });
        const selectStateDisplay = find(selectedColumns, { selectDisplayGroupId });
        if (selectState) return selectState.dataKey === dataKey;

        /**
         * Column is selected if its selectDisplayGroupId is selected and it is selected by default or selectDisplayGroupId is selected by default
         */

        if (
          options?.selectDisplayGroupId &&
          options?.selectGroupId !== options?.selectDisplayGroupId
        ) {
          const selectedGroupColumnOptions = find(
            columns,
            ({ options: colOptions }) =>
              colOptions?.selectGroupId !== selectGroupId &&
              colOptions?.selectDisplayGroupId === selectDisplayGroupId,
          )?.options;

          const isInSelectState = !!find(selectedColumns, {
            selectGroupId: selectedGroupColumnOptions?.selectGroupId,
          });

          if (!selectedColumns.length || !isInSelectState)
            return !!options.selectDefault && !!selectedGroupColumnOptions?.selectDefault;

          return !!options.selectDefault && !!selectStateDisplay;
        }

        return !!options.selectDefault;
      }

      if (options?.selectDisplayGroupId) {
        const { selectDisplayGroupId } = options;
        // Find the column that controls this display group
        const selectGroupColumn = find(
          columns,
          ({ options }) =>
            !!options?.selectGroupId && options?.selectDisplayGroupId === selectDisplayGroupId,
        );

        if (selectGroupColumn?.options?.selectGroupId) {
          const { selectGroupId } = selectGroupColumn.options;
          const selectColumnState = find(selectedColumns, { selectGroupId });
          return selectColumnState
            ? !!find(selectedColumns, { selectDisplayGroupId })
            : !!find(columns, {
                options: { selectDefault: true, selectGroupId, selectDisplayGroupId },
              });
        }

        return false;
      }

      return false;
    },
    [columns, selectedColumns],
  );

  /**
   * A helper function that determines whether the column is visible
   */
  const isColumnVisible = useCallback<(column: TableColumnResult) => boolean>(
    (column) => {
      return !isColumnSelectable(column) || isColumnSelected(column);
    },
    [isColumnSelectable, isColumnSelected],
  );

  /**
   * Function to check if selectedColumn value exist in new columns
   */
  const mappedColumns = () => {
    const lookup: { [key: string]: boolean } = {};
    columns?.forEach((item) => {
      if (isColumnSelectable(item)) {
        lookup[item.dataKey] = true;
      }
    });

    return selectedColumns?.filter((item) => lookup[item.dataKey]);
  };

  useEffect(() => {
    if (selectedColumns?.length !== mappedColumns()?.length) {
      setSelectedColumns(mappedColumns() ?? []);
    }
  }, [selectedColumns, columns, mappedColumns, setSelectedColumns]);

  const selectColumn = useCallback<(column: TableColumnResult) => void>(
    ({ dataKey, options }) => {
      if (options?.selectGroupId) {
        const { selectGroupId, selectDisplayGroupId } = options;

        // DependentDefault identifies the default `selectGroupId` from the `columns` array when no specific selection is made
        // for the given `selectDisplayGroupId`. It searches for a column where the `selectDisplayGroupId` matches.
        const dependentDefault = columns?.find(
          (item) => item?.options?.selectDisplayGroupId === selectDisplayGroupId,
        )?.options?.selectGroupId;

        setSelectedColumns((prevState) => {
          const dependentItem =
            prevState?.find((item) => item.selectGroupId === selectGroupId)?.selectDisplayGroupId ??
            dependentDefault;

          const updatedState = unionBy(
            [{ selectGroupId, selectDisplayGroupId, dataKey }],
            prevState,
            'selectGroupId',
          );

          return dependentItem === selectDisplayGroupId
            ? updatedState
            : updatedState.filter((item) => item.selectDisplayGroupId !== dependentItem);
        });
      }
    },
    [columns],
  );

  return {
    selectedColumns,
    setSelectedColumns,
    isColumnSelectable,
    isColumnSelected,
    isColumnVisible,
    selectColumn,
  } as const;
}
