import { Sentiment } from '@aily/graphql-sdk/schema';
import { Box, Stack, styled, Typography } from '@mui/material';
import { map } from 'lodash-es';
import React from 'react';

import { colors } from '../../../../theme/default/colors';
import { mapSentimentToColor } from '../../../../utils';

interface PillValue {
  title: string;
  subtitle: string | null;
  content: string;
  sentiment: Sentiment;
}

interface AgentInfoProps {
  pillValues?: PillValue[];
}

const StyledButton = styled(Stack)(() => ({
  borderRadius: 50,
  backgroundColor: 'rgba(14, 51, 31, 1)',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 40,
  width: 250,
  display: 'flex',
  flexDirection: 'row',
  padding: 10,
  paddingLeft: 15,
  paddingRight: 15,
  flexWrap: 'wrap',
}));

const PillValue: React.FC<PillValue> = (pill, index) => {
  const itemColor = mapSentimentToColor(pill.sentiment.toUpperCase() as Sentiment);
  return (
    <Box
      data-testid="decisions_info_panel"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      gap="5px"
      key={index}
    >
      <Typography variant="bodyBoldTight" color={itemColor}>
        {pill.title}
      </Typography>
      <Typography variant="smallBold" color={colors.neutrals.white}>
        {pill.content}
      </Typography>
    </Box>
  );
};

const AgentInfoComponent: React.FC<AgentInfoProps> = ({ pillValues }) => {
  if (!pillValues) return null;
  return (
    <Box data-testid="decisions_info_panel" display="flex" justifyContent="center">
      <StyledButton gap="7px">{map(pillValues, PillValue)}</StyledButton>
    </Box>
  );
};

export default AgentInfoComponent;
