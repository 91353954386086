import { useTakeAgentDecisionByUserMutation } from '@aily/graphql-sdk/modules/pro';
import * as T from '@aily/graphql-sdk/schema';
import { WispSvg } from '@aily/saas-core/features/Agent';
import { Heading, HStack, VStack } from '@aily-labs/ui';
import { Box, Button, CircularProgress, styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useModule } from '../../../providers';
import { RealsTextBlockBuilder } from '.';

export const CARD_WIDTH = 342;
export const CARD_HEIGHT = 520;
export const CARD_GAP = 24;
export const CARD_TOTAL_WIDTH = CARD_WIDTH + CARD_GAP;

interface CardProps {
  isActive: boolean;
  isVisible: boolean;
  median: number;
  allScreensAreVisible: boolean;
  index: number;
  visibleCards: number;
  showBoxShadow: boolean;
}

interface WispContainerProps {
  activeCardPosition?: number;
}

interface RealScreenProps {
  data: T.RealScreen;
  index: number;
  isVisible: boolean;
  activeIndex: number;
  numberOfScreens: number;
  allScreensAreVisible: boolean;
  pauseAudio: boolean;
  visibleCards: number;
  onClick: () => void;
  handleLetsDo: () => void;
  audio: React.ReactNode;
}

const calculateCardTransform = (
  median = 0,
  index = 0,
  visibleCards = 0,
  isVisible?: boolean,
  isActive?: boolean,
  allScreensAreVisible?: boolean,
): string => {
  let translatePercentage = 0;
  if (!isVisible) {
    // If the card is not visible, translate it by the median * 100% so that it positioned at
    // the middle of the screen but wont be visible until isVisible is true because visiblity is controlled by opacity
    translatePercentage = median * 100;
  } else if (isActive && !allScreensAreVisible && index >= visibleCards) {
    // If the card is active and not all screens are visible, translate it by the median * 100%
    translatePercentage = median * 100;
  }
  // Otherwise, the translatePercentage remains 0

  // Combine the percentage translation with the fixed card gap
  return `translateX(calc(${translatePercentage}% + ${CARD_GAP}px))`;
};

const Card = styled(Box)<CardProps>(
  ({ allScreensAreVisible, isActive, isVisible, median, index, visibleCards, showBoxShadow }) => ({
    backgroundColor: 'rgba(64, 64, 64, 0.35)',
    borderRadius: 12,
    backdropFilter: `blur(22.5px)`,
    flex: `0 0 ${CARD_WIDTH}px`,
    marginRight: CARD_GAP,
    width: CARD_WIDTH,
    maxWidth: CARD_WIDTH,
    minHeight: CARD_HEIGHT,
    height: CARD_HEIGHT,
    padding: 32,
    opacity: isVisible ? 1 : 0,
    zIndex: isVisible ? -1 : 1,
    transform: calculateCardTransform(
      median,
      index,
      visibleCards,
      isVisible,
      isActive,
      allScreensAreVisible,
    ),

    boxShadow: showBoxShadow ? '0px 0px 100px 0px rgba(49, 212, 182, 0.50)' : 'none',
    transition: `opacity 0.5s ease, transform 3s ease, box-shadow 0s linear, box-shadow 01s ease-out`,
    position: 'relative',
  }),
);

const LoadingIcon = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const WispContainer = styled(Box)<WispContainerProps>(() => ({
  position: 'absolute',
  top: -90,
  left: 230,
  width: '100%',
  height: '100%',
  overflow: 'visible',
  transition: 'left 1s ease, opacity 5s ease',
  pointerEvents: 'none',
  mixBlendMode: 'multiply',
  zIndex: -1,

  '& .active': {
    mixBlendMode: 'soft-light',
  },
}));

const StyledButton = styled(Button)<{ isVisible?: boolean }>(({ isVisible, theme }) => ({
  borderRadius: 9999,
  width: 200,
  height: 40,
  marginLeft: theme.spacing(6),
  opacity: isVisible ? 1 : 0,
  transform: `translateX(${isVisible ? 0 : 100}%)`,
  transition: 'opacity 8s ease, transform 4s ease',
  backdropFilter: `blur(22.5px)`,
}));

const ButtonText = styled(Typography)({
  fontSize: 12,
  fontWeight: '800',
  lineHeight: 12,
  textAlign: 'center',
  color: 'black',
});

const Content: React.FC<{ screen: T.RealScreen; index: number }> = ({ screen }) => (
  <VStack maxHeight={437} overflow="hidden">
    <RealsTextBlockBuilder
      data={screen?.content?.items as T.RealTextListItem[]}
      title={
        <HStack width={291} minHeight={40}>
          <Heading color="$white" type="H9">
            {screen?.header?.title}
          </Heading>
        </HStack>
      }
    />
  </VStack>
);

const ActionButton = ({
  data,
  handleLetsDo,
  isVisible,
}: Pick<RealScreenProps, 'data' | 'handleLetsDo' | 'isVisible'>) => {
  const currentModule = useModule();
  const [takeDecision, { loading }] = useTakeAgentDecisionByUserMutation();

  const handleTakeDecision = async () => {
    await takeDecision({
      variables: {
        input: {
          agentId: data.screenId,
          screenIds: [],
          titleLabel: data.header?.title ?? '',
          subtitle: data.header?.subtitle ?? '',
          moduleCode: currentModule?.moduleCode ?? '',
          decisionType: T.FeedDecisionType.Real,
        },
      },
    });

    handleLetsDo();
  };

  return (
    <div style={{ display: 'table-row', verticalAlign: 'bottom' }}>
      <StyledButton
        variant="primary"
        onClick={handleTakeDecision}
        isVisible={isVisible}
        endIcon={loading && <LoadingIcon size={20} />}
        data-testid="test-id-lets-do-it-button"
      >
        <ButtonText>LET'S DO IT</ButtonText>
      </StyledButton>
    </div>
  );
};

export const RealScreen: React.FC<RealScreenProps> = ({
  data,
  index,
  isVisible,
  activeIndex,
  numberOfScreens,
  pauseAudio,
  visibleCards,
  handleLetsDo,
  onClick,
  audio,
}) => {
  const isActive = activeIndex === index;
  const medianValue = numberOfScreens <= 3 ? 1.75 : 2;
  const medianDiff = medianValue < activeIndex ? activeIndex - index : medianValue - index;
  const [showBoxShadow, setShowBoxShadow] = useState(false);
  const [allScreensAreVisible, setAllScreensAreVisible] = useState(false);

  useEffect(() => {
    setShowBoxShadow(isActive && !allScreensAreVisible);
    const timer = setTimeout(() => {
      setShowBoxShadow(false);
      setAllScreensAreVisible(visibleCards === numberOfScreens - 1);
    }, 4000);

    return () => clearTimeout(timer);
  }, [isActive, numberOfScreens, allScreensAreVisible, visibleCards]);

  return (
    <>
      <Card
        isActive={isActive}
        isVisible={isVisible}
        allScreensAreVisible={allScreensAreVisible}
        onClick={isVisible ? onClick : undefined}
        median={medianDiff}
        index={index}
        visibleCards={visibleCards}
        showBoxShadow={showBoxShadow}
        display="table"
      >
        <Content screen={data} index={index} />
        <WispContainer>
          <WispSvg className={isActive && !pauseAudio ? 'active' : ''} opacity={isActive ? 1 : 0} />
        </WispContainer>
        {index === numberOfScreens - 1 && (
          <ActionButton data={data} isVisible={isVisible} handleLetsDo={handleLetsDo} />
        )}
        {audio}
      </Card>
    </>
  );
};
