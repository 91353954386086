import React from 'react';

import ButtonBase, { ButtonBaseProps } from './ButtonBase';

/**
 * Conditionally wraps children with a `ButtonBase` if `onClick` is provided.
 */
const MaybeButtonBase: React.FC<ButtonBaseProps> = ({ onClick, children, ...rest }) => {
  if (!onClick) {
    return <>{children}</>;
  }

  return (
    <ButtonBase onClick={onClick} {...rest}>
      {children}
    </ButtonBase>
  );
};

export default MaybeButtonBase;
