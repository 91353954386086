import * as T from '@aily/graphql-sdk/schema';
import { useLazyQuery } from '@aily/saas-graphql-client';
import { ApolloError, DocumentNode, TypedDocumentNode } from '@apollo/client';
import { OperationVariables } from '@apollo/client/core/types';
import { isEqual } from 'lodash-es';
import React, { useCallback, useEffect, useState } from 'react';

import { resolveFilterValues } from '../utils';

export interface UseFiltersProps<TData, TVariables> {
  query: DocumentNode | TypedDocumentNode<TData, TVariables>;
  variables?: TVariables;
  getFilters?: (data: TData) => T.Filter[];
  defaultFilterValues?: T.FilterValue[];
  onFilterValuesChanged?: (filterValues: T.FilterValue[]) => void;
  skip?: boolean;
}

export interface UseFiltersResult {
  filters: T.Filter[];
  loading: boolean;
  filterValues: T.FilterValue[];
  setFilterValues: React.Dispatch<React.SetStateAction<T.FilterValue[]>>;
  refetch: () => void;
  error?: ApolloError;
}

export function useFilters<
  TData = T.Query,
  TVariables extends OperationVariables = OperationVariables,
>({
  query,
  variables,
  getFilters,
  defaultFilterValues = [],
  onFilterValuesChanged,
  skip,
}: UseFiltersProps<TData, TVariables>): UseFiltersResult {
  const [filters, setFilters] = useState<T.Filter[]>([]);
  const [filterValues, setFilterValues] = useState(defaultFilterValues);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ApolloError>();
  const [localVariables, setLocalVariables] = useState(variables);
  // This helps to set the correct loading state in the same render the change occurred
  const variablesChanged = !isEqual(variables, localVariables);

  const onCompleted = useCallback(
    (data: TData) => {
      const newFilters = getFilters?.(data) ?? [];
      setFilters(newFilters);

      const newFilterValues = resolveFilterValues(
        newFilters,
        filterValues.length ? filterValues : defaultFilterValues,
      );
      if (!isEqual(filterValues, newFilterValues)) {
        setFilterValues(newFilterValues);
        onFilterValuesChanged?.(newFilterValues);
      }

      setLoading(false);
    },
    [filterValues, onFilterValuesChanged, getFilters],
  );

  const onError = useCallback((error: ApolloError) => {
    setError(error);
  }, []);

  const [fetchData] = useLazyQuery(query, { onCompleted, onError });

  // The `doFetchData` callback sets the loading state to true
  // and initiates data fetching using the current state of the variables
  const doFetchData = useCallback(() => {
    setLoading(true);
    fetchData({ variables: localVariables });
  }, [localVariables, fetchData]);

  // This hook executes the `doFetchData` callback every time it changes
  // and the `skip` property is not set to true
  useEffect(() => {
    if (!skip) {
      doFetchData();
    }
  }, [skip, doFetchData]);

  useEffect(() => {
    if (!isEqual(variables, localVariables)) {
      // In the next render, the `doFetchData` callback will be re-created,
      // and the render after it will be executed by the dependent hook
      setLocalVariables(variables);
      setLoading(true);
    }
  }, [variables, localVariables]);

  return {
    filters,
    loading: loading || variablesChanged,
    filterValues,
    setFilterValues,
    refetch: doFetchData,
    error,
  };
}
