import * as T from '@aily/graphql-sdk/schema';

/**
 * TypeScript interfaces reflecting the structure of manually assembled Real JSON data.
 * These types are crucial for defining the expected data structures and ensuring type safety.
 *
 * **Note:**
 * As the JSON data is prone to changes and may contain errors, it's essential to update
 * these interfaces whenever the JSON structure is modified.
 */
export interface AgentJsonData {
  id: string;
  run_id: number;
  security_info: SecurityInfo;
  imp_agent?: Imp_agent;
  opt_agent?: Imp_agent;
  tags: Tags;
  publication_date: string;
  expiration_date: string;
  time_stamp: string;
  title: string;
  audio_path: string;
  headline: string;
  sub_headline: string;
  screens: Screen[];
}

interface Imp_agent {
  name: string;
  agent_type: string;
  impact: Impact;
  modules: string[];
}

interface Impact {
  text: string;
  sentiment: string;
}

export interface OptimisationLever {
  content: Content[];
  name: string;
  num_free_levers: number;
  screen_dict: ScreenDict;
}

interface PillValue {
  title: string;
  subtitle: string | null;
  content: string;
  sentiment: T.Sentiment;
}

export interface ScreenDict {
  [key: string]: {
    name: string;
    button_value: string[] | null;
    pill_values: PillValue[];
    is_recommended: boolean;
    screens: Screen[];
  };
}

export interface ScreenDictScreen extends Screen {
  is_recommended?: boolean;
}

export interface Screen {
  screen_id: number;
  screen_type: ScreenType;
  screen_title: string;
  screen_subtitle?: string;
  audio_track: string;
  content: Content[];
  popup?: Popup;
  optimisation_levers?: OptimisationLever[];
  scenario_screen_ids: number[];
  is_default: boolean;
  title_card: string;
  card_value: number;
  module_id: string[];
}

export interface Content {
  overall_sentiment: T.Sentiment;
  elements: Element[][][];
}

export type Element = TextElement &
  SliderElement &
  LogosElement &
  ButtonElement &
  ChartElement &
  ImagesElement &
  ProgressBarElement &
  DecisionButtonElement;

export interface BaseElement<Content> {
  id: string;
  type: ElementType;
  content?: Content;
}

export enum ElementType {
  Slider = 'slider',
  Text = 'text',
  SubtitleText = 'subtitle_text',
  Chart = 'chart',
  ProgressBar = 'progress_bars',
  Switch = 'switch',
  Button = 'button',
  Info = 'info',
  Images = 'images',
  Logos = 'logos',
  Trend = 'trend',
  LeaderBoard = 'leaderboard',
  DecisionButton = 'decision_button',
  HorizontalLine = 'horizontal_line',
}

export interface ButtonElement extends BaseElement<object> {
  title: string;
}

export interface DecisionButtonElement {
  content: string;
  outro_body: string;
}

type Logo = '.ci' | '.fi' | '.rd';

export interface InfoElement extends BaseElement<object> {
  description: string;
}

export interface LogosElement extends BaseElement<Logo[]> {
  description: string;
}

export interface ChartElement extends BaseElement<ChartContent> {
  id: string;
  description: string;
}

export enum ChartType {
  line = 'line',
  line_delta = 'line_delta',
  bar = 'bar',
}

export interface XAxis {
  axis_type: string;
}

export interface YAxis {
  axis_type: string;
}

export interface Coords {
  x: string;
  y: Y;
}

export interface Y {
  value: Value;
}

export interface Value {
  raw: number;
  title: string;
  formatted: string;
}

export interface Series {
  id: string;
  name: string;
  series_type: string;
  sentiment: string;
  line_style: string;
  show_in_legend: boolean;
  show_in_tooltip: boolean;
  tooltip_range_format: any;
  tooltip_custom_type: string;
  marker_format?: 'CIRCLE' | 'NONE';
  data: Coords[];
}

export interface ChartContent {
  title: string;
  url: string;
  x_axis: XAxis;
  y_axis: YAxis;
  x_label: string;
  y_label: string;
  x_ticks: number[];
  y_ticks: number[];
  sentiment: T.Sentiment;
  chart_type: ChartType;
  tick_amount?: 3;
  series: Series[];
}

export interface ProgressBarValue {
  value: number;
  formatted_value: string;
  formatted_value2: string;
  unit?: string | 'M' | 'B';
  sentiment: 'positive' | 'negative';
  description: string;
  star: boolean;
}

export interface ProgressBarContent {
  id: string;
  name: string;
  data: ProgressBarValue[];
}

export interface ProgressBarElement extends BaseElement<ProgressBarContent[]> {
  title: string;
  description: string;
  max_value: number;
}

export interface TextElement extends BaseElement<object> {
  text_type: 'H2' | 'H4' | 'H6' | 'H8';
  sentiment?: T.Sentiment;
  url: string;
  text_link: string;
  icon?: string;
  alignment: 'left' | 'center' | 'right';
  display_icon: boolean;
  underline?: boolean;
}

export interface ExtendedTextElement extends Omit<TextElement, 'content'> {
  content: string | object;
}

export interface ImagesElement extends BaseElement<object> {
  asset: string;
  width: number;
  height: number;
}

export interface SliderElement extends BaseElement<object> {
  name: string;
  default_value: number;
  description: string;
  variables: string[];
}

export interface Text {
  content?: string;
  style?: Style;
}

export enum Style {
  Base = 'base',
  BaseTiny = 'base_tiny',
  BaseTinyGreen = 'base_tiny_green',
  BaseTinyRed = 'base_tiny_red',
  BaseSmall = 'base_small',
  BaseSmallGreen = 'base_small_green',
  BaseSmallRed = 'base_small_red',
  BigGreen = 'big_green',
  BigBoldGreen = 'big_bold_green',
  MediumGreen = 'medium_green',
  SmallGreen = 'small_green',
  MediumRed = 'medium_red',
  BigRed = 'big_red',
  SmallRed = 'small_red',
  SmallTile = 'smallTile',
}

export interface Popup {
  content: any;
  button_logo: string;
  button_title: string;
  screens: Screen[];
}

export interface PopupContent {
  texts?: Array<Text[] | Text>;
}

export enum ScreenType {
  Default = 'default',
  Decision = 'decision',
  Levers = 'levers',
  Popup = 'popup',
  Intro = 'intro',
  Outro = 'outro',
  Title = 'title',
  LeaderBoard = 'leaderboard',
}

export interface SecurityInfo {
  view_code?: string[];
  security_group?: string[];
}

export interface Tags {
  priorities: string[];
  geography: string[];
  sentiment: string;
}

export interface SliderValue {
  label?: string;
  name?: string;
  selectedValue?: number;
  positions?: number;
}

export interface SuperSliderValueType {
  [key: string]: SliderValueType;
}

export interface SliderValueType {
  [id: string]: SliderValue;
}

export interface DecisionInput {
  name?: string;
  label?: string;
}
