import React from 'react';

import { parseLinearGradient } from '../../../utils';

export interface ProgressBarGradientProps {
  id: string;
  linearGradient: string;
}

const ProgressBarGradient: React.FC<ProgressBarGradientProps> = ({ id, linearGradient }) => {
  const { gradientTransform, stops } = parseLinearGradient(linearGradient);

  // Apply a 90-degree clockwise rotation to the gradient to negate the progress bar circle's counter-clockwise rotation
  const adjustedGradientTransform = `${gradientTransform} rotate(90, 0.5, 0.5)`;

  return (
    <defs>
      <linearGradient id={id} gradientTransform={adjustedGradientTransform}>
        {stops.map((stop, index) => (
          <stop key={index} offset={stop.offset} stopColor={stop.color} />
        ))}
      </linearGradient>
    </defs>
  );
};

export default ProgressBarGradient;
