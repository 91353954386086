import { gql } from '@apollo/client';

import type {
  DataViewFields_GraHomeDialsDataView_Fragment,
  FilterValueFields_MultiSelectFilterValue_Fragment,
  FilterValueFields_SelectFilterValue_Fragment,
  FilterValueFields_SelectGroupFilterValue_Fragment,
  TableColumnFieldsFragment,
  TextFieldsFragment,
} from '../../../core/__generated__/fragments';
import {
  DataViewFieldsFragmentDoc,
  FilterValueFieldsFragmentDoc,
  TableColumnFieldsFragmentDoc,
  TextFieldsFragmentDoc,
} from '../../../core/__generated__/fragments';
import type * as T from '../../../schema/__generated__/types';
export type GraHomeDialsDataViewFieldsFragment = {
  __typename?: 'GraHomeDialsDataView';
  title: string;
  annotation?: string | null;
  items?: Array<
    { __typename?: 'GraHomeDialsItemDataView' } & GraHomeDialsItemDataViewFieldsFragment
  > | null;
} & DataViewFields_GraHomeDialsDataView_Fragment;

export type GraHomeDialsItemDataViewFieldsFragment = {
  __typename?: 'GraHomeDialsItemDataView';
  dialItem?: ({ __typename?: 'DialItem' } & DialItemFieldsFragment) | null;
};

export type DialItemFieldsFragment = {
  __typename?: 'DialItem';
  label: string;
  sentiment: T.Color;
  progress: number;
  target?: number | null;
  progressText?: { __typename?: 'TextResult'; value?: string | null } | null;
  targetText?: { __typename?: 'TextResult'; value?: string | null } | null;
  actualText?: { __typename?: 'TextResult'; value?: string | null } | null;
  varianceText?: ({ __typename?: 'TextResult' } & TextFieldsFragment) | null;
  varianceAIText?: ({ __typename?: 'TextResult' } & TextFieldsFragment) | null;
  link?: ({ __typename?: 'TextLinkResult' } & TextLinkFieldsFragment) | null;
};

export type MetaDataFieldsFragment = {
  __typename?: 'MetaData';
  key?: string | null;
  label?: string | null;
  value?: string | null;
};

export type GraHomeCardsItemDataViewFieldsFragment = {
  __typename?: 'GraHomeCardsItemDataView';
  id: string;
  title: string;
  subtitle: string;
  absoluteYtd: string;
  pctVsPy: string;
  pctVsPySentiment: T.Sentiment;
  linkResult?: ({ __typename?: 'Link' } & LinkFieldsFragment) | null;
};

export type GraFacilitatedPathwaysRecommendedTableDataViewFieldsFragment = {
  __typename?: 'GraRecommendedPathwaysTableDataView';
  lastUpdateDate: string;
  columns?: Array<{ __typename?: 'TableColumnResult' } & TableColumnFieldsFragment> | null;
  rows?: Array<
    | ({ __typename?: 'TableRowResult' } & GraFacilitatedPathwaysRecommendedTableRowFieldsFragment)
    | null
  > | null;
  filters?: Array<
    | ({
        __typename?: 'MultiSelectFilterValue';
      } & FilterValueFields_MultiSelectFilterValue_Fragment)
    | ({ __typename?: 'SelectFilterValue' } & FilterValueFields_SelectFilterValue_Fragment)
    | ({
        __typename?: 'SelectGroupFilterValue';
      } & FilterValueFields_SelectGroupFilterValue_Fragment)
  > | null;
};

export type GraFacilitatedPathwaysRecommendedTableRowFieldsFragment = {
  __typename?: 'TableRowResult';
  rowId: number;
  rowType: T.RowType;
  cells?: Array<
    { __typename?: 'TableCellResult' } & GraFacilitatedPathwaysRecommendedTableCellFieldsFragment
  > | null;
};

export type GraFacilitatedPathwaysRecommendedTableCellFieldsFragment = {
  __typename?: 'TableCellResult';
  columnDataKey: string;
  cellContent?:
    | ({
        __typename?: 'BadgeResult';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_BadgeResult_Fragment)
    | ({
        __typename?: 'ColoredVarianceResult';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_ColoredVarianceResult_Fragment)
    | ({
        __typename?: 'ColoredVarianceWithDeltaResult';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_ColoredVarianceWithDeltaResult_Fragment)
    | ({
        __typename?: 'ComparatorTrendResult';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_ComparatorTrendResult_Fragment)
    | ({
        __typename?: 'CriteriaBadgeResult';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_CriteriaBadgeResult_Fragment)
    | ({
        __typename?: 'Delta';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_Delta_Fragment)
    | ({
        __typename?: 'DrillDownResult';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_DrillDownResult_Fragment)
    | ({
        __typename?: 'GraphicElementResult';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_GraphicElementResult_Fragment)
    | ({
        __typename?: 'IconLinkResult';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_IconLinkResult_Fragment)
    | ({
        __typename?: 'IconsLinkResult';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_IconsLinkResult_Fragment)
    | ({
        __typename?: 'KpiStatus';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_KpiStatus_Fragment)
    | ({
        __typename?: 'KpiStatusOverview';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_KpiStatusOverview_Fragment)
    | ({
        __typename?: 'LocationMarkerResult';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_LocationMarkerResult_Fragment)
    | ({
        __typename?: 'MicroChartResult';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_MicroChartResult_Fragment)
    | ({
        __typename?: 'ProgressBarResult';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_ProgressBarResult_Fragment)
    | ({
        __typename?: 'RegionCountryResult';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_RegionCountryResult_Fragment)
    | ({
        __typename?: 'RiskIndicatorResult';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_RiskIndicatorResult_Fragment)
    | ({
        __typename?: 'StatusTextResult';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_StatusTextResult_Fragment)
    | ({
        __typename?: 'StudyDesignLinkResult';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_StudyDesignLinkResult_Fragment)
    | ({
        __typename?: 'TextExtendedResult';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_TextExtendedResult_Fragment)
    | ({
        __typename?: 'TextLinkResult';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_TextLinkResult_Fragment)
    | ({
        __typename?: 'TextResult';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_TextResult_Fragment)
    | ({
        __typename?: 'Timeline';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_Timeline_Fragment)
    | ({
        __typename?: 'TimelineDataPoint';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_TimelineDataPoint_Fragment)
    | ({
        __typename?: 'TimelinePhases';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_TimelinePhases_Fragment)
    | ({
        __typename?: 'ValueWithVarianceResult';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_ValueWithVarianceResult_Fragment)
    | ({
        __typename?: 'VarianceResult';
      } & GraFacilitatedPathwaysRecommendedTableCellContentFields_VarianceResult_Fragment)
    | null;
};

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_BadgeResult_Fragment = {
  __typename?: 'BadgeResult';
};

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_ColoredVarianceResult_Fragment =
  { __typename?: 'ColoredVarianceResult' };

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_ColoredVarianceWithDeltaResult_Fragment =
  { __typename?: 'ColoredVarianceWithDeltaResult' };

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_ComparatorTrendResult_Fragment =
  { __typename?: 'ComparatorTrendResult' };

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_CriteriaBadgeResult_Fragment = {
  __typename?: 'CriteriaBadgeResult';
  label?: string | null;
  criteriaSentiment?: T.Sentiment | null;
  criteriaPercentage?: string | null;
};

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_Delta_Fragment = {
  __typename?: 'Delta';
};

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_DrillDownResult_Fragment = {
  __typename?: 'DrillDownResult';
  value?: string | null;
  subLabels?: Array<string> | null;
  drillUpId?: number | null;
  drillDownId?: number | null;
  drillUpIds?: Array<number> | null;
  drillDownIds?: Array<number | null> | null;
};

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_GraphicElementResult_Fragment =
  { __typename?: 'GraphicElementResult' };

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_IconLinkResult_Fragment = {
  __typename?: 'IconLinkResult';
};

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_IconsLinkResult_Fragment = {
  __typename?: 'IconsLinkResult';
};

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_KpiStatus_Fragment = {
  __typename?: 'KpiStatus';
};

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_KpiStatusOverview_Fragment = {
  __typename?: 'KpiStatusOverview';
};

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_LocationMarkerResult_Fragment =
  { __typename?: 'LocationMarkerResult' };

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_MicroChartResult_Fragment = {
  __typename?: 'MicroChartResult';
};

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_ProgressBarResult_Fragment = {
  __typename?: 'ProgressBarResult';
};

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_RegionCountryResult_Fragment = {
  __typename?: 'RegionCountryResult';
};

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_RiskIndicatorResult_Fragment = {
  __typename?: 'RiskIndicatorResult';
};

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_StatusTextResult_Fragment = {
  __typename?: 'StatusTextResult';
};

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_StudyDesignLinkResult_Fragment =
  { __typename?: 'StudyDesignLinkResult' };

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_TextExtendedResult_Fragment = {
  __typename?: 'TextExtendedResult';
};

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_TextLinkResult_Fragment = {
  __typename?: 'TextLinkResult';
} & TextLinkFieldsFragment;

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_TextResult_Fragment = {
  __typename?: 'TextResult';
} & TextFieldsFragment;

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_Timeline_Fragment = {
  __typename?: 'Timeline';
};

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_TimelineDataPoint_Fragment = {
  __typename?: 'TimelineDataPoint';
};

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_TimelinePhases_Fragment = {
  __typename?: 'TimelinePhases';
};

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_ValueWithVarianceResult_Fragment =
  { __typename?: 'ValueWithVarianceResult' };

export type GraFacilitatedPathwaysRecommendedTableCellContentFields_VarianceResult_Fragment = {
  __typename?: 'VarianceResult';
  value?: string | null;
  actual?: string | null;
  target?: string | null;
  label?: string | null;
  subLabels?: Array<string> | null;
  sentiment?: T.Sentiment | null;
};

export type GraFacilitatedPathwaysRecommendedTableCellContentFieldsFragment =
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_BadgeResult_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_ColoredVarianceResult_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_ColoredVarianceWithDeltaResult_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_ComparatorTrendResult_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_CriteriaBadgeResult_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_Delta_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_DrillDownResult_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_GraphicElementResult_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_IconLinkResult_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_IconsLinkResult_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_KpiStatus_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_KpiStatusOverview_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_LocationMarkerResult_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_MicroChartResult_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_ProgressBarResult_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_RegionCountryResult_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_RiskIndicatorResult_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_StatusTextResult_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_StudyDesignLinkResult_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_TextExtendedResult_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_TextLinkResult_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_TextResult_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_Timeline_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_TimelineDataPoint_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_TimelinePhases_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_ValueWithVarianceResult_Fragment
  | GraFacilitatedPathwaysRecommendedTableCellContentFields_VarianceResult_Fragment;

export type LinkFieldsFragment = {
  __typename?: 'Link';
  pageId?: string | null;
  absolutePath?: string | null;
  drillId?: number | null;
  drillIds?: Array<number> | null;
  target?: T.LinkTargetType | null;
  plailistId?: string | null;
  previousPriorityId?: number | null;
  filters?: Array<
    | ({
        __typename?: 'MultiSelectFilterValue';
      } & FilterValueFields_MultiSelectFilterValue_Fragment)
    | ({ __typename?: 'SelectFilterValue' } & FilterValueFields_SelectFilterValue_Fragment)
    | ({
        __typename?: 'SelectGroupFilterValue';
      } & FilterValueFields_SelectGroupFilterValue_Fragment)
  > | null;
};

export type TextLinkFieldsFragment = {
  __typename?: 'TextLinkResult';
  value?: string | null;
  label?: string | null;
  subLabels?: Array<string> | null;
  linkDirection?: T.LinkDirection | null;
  linkResult?: ({ __typename?: 'Link' } & LinkFieldsFragment) | null;
};

export const MetaDataFieldsFragmentDoc = gql`
  fragment MetaDataFields on MetaData {
    key
    label
    value
  }
`;
export const LinkFieldsFragmentDoc = gql`
  fragment LinkFields on Link {
    pageId
    absolutePath
    drillId
    drillIds
    filters {
      ...FilterValueFields
    }
    target
    plailistId
    previousPriorityId
  }
  ${FilterValueFieldsFragmentDoc}
`;
export const TextLinkFieldsFragmentDoc = gql`
  fragment TextLinkFields on TextLinkResult {
    value
    label
    subLabels
    linkResult {
      ...LinkFields
    }
    linkDirection
  }
  ${LinkFieldsFragmentDoc}
`;
export const DialItemFieldsFragmentDoc = gql`
  fragment DialItemFields on DialItem {
    label
    sentiment
    progress
    progressText {
      value
    }
    target
    targetText {
      value
    }
    actualText {
      value
    }
    varianceText {
      ...TextFields
    }
    varianceAIText {
      ...TextFields
    }
    link {
      ...TextLinkFields
    }
  }
  ${TextFieldsFragmentDoc}
  ${TextLinkFieldsFragmentDoc}
`;
export const GraHomeDialsItemDataViewFieldsFragmentDoc = gql`
  fragment GraHomeDialsItemDataViewFields on GraHomeDialsItemDataView {
    dialItem {
      ...DialItemFields
    }
  }
  ${DialItemFieldsFragmentDoc}
`;
export const GraHomeDialsDataViewFieldsFragmentDoc = gql`
  fragment GraHomeDialsDataViewFields on GraHomeDialsDataView {
    ...DataViewFields
    title
    annotation
    items {
      ...GraHomeDialsItemDataViewFields
    }
  }
  ${DataViewFieldsFragmentDoc}
  ${GraHomeDialsItemDataViewFieldsFragmentDoc}
`;
export const GraHomeCardsItemDataViewFieldsFragmentDoc = gql`
  fragment GraHomeCardsItemDataViewFields on GraHomeCardsItemDataView {
    id
    title
    subtitle
    absoluteYtd
    pctVsPy
    pctVsPySentiment
    linkResult {
      ...LinkFields
    }
  }
  ${LinkFieldsFragmentDoc}
`;
export const GraFacilitatedPathwaysRecommendedTableCellContentFieldsFragmentDoc = gql`
  fragment GraFacilitatedPathwaysRecommendedTableCellContentFields on TableCellContent {
    ... on TextResult {
      ...TextFields
    }
    ... on DrillDownResult {
      value
      subLabels
      drillUpId
      drillDownId
      drillUpIds
      drillDownIds
    }
    ... on TextLinkResult {
      ...TextLinkFields
    }
    ... on VarianceResult {
      value
      actual
      target
      label
      subLabels
      sentiment
    }
    ... on CriteriaBadgeResult {
      label
      criteriaSentiment
      criteriaPercentage
    }
  }
  ${TextFieldsFragmentDoc}
  ${TextLinkFieldsFragmentDoc}
`;
export const GraFacilitatedPathwaysRecommendedTableCellFieldsFragmentDoc = gql`
  fragment GraFacilitatedPathwaysRecommendedTableCellFields on TableCellResult {
    columnDataKey
    cellContent {
      ...GraFacilitatedPathwaysRecommendedTableCellContentFields
    }
  }
  ${GraFacilitatedPathwaysRecommendedTableCellContentFieldsFragmentDoc}
`;
export const GraFacilitatedPathwaysRecommendedTableRowFieldsFragmentDoc = gql`
  fragment GraFacilitatedPathwaysRecommendedTableRowFields on TableRowResult {
    rowId
    rowType
    cells {
      ...GraFacilitatedPathwaysRecommendedTableCellFields
    }
  }
  ${GraFacilitatedPathwaysRecommendedTableCellFieldsFragmentDoc}
`;
export const GraFacilitatedPathwaysRecommendedTableDataViewFieldsFragmentDoc = gql`
  fragment GraFacilitatedPathwaysRecommendedTableDataViewFields on GraRecommendedPathwaysTableDataView {
    columns {
      ...TableColumnFields
    }
    rows {
      ...GraFacilitatedPathwaysRecommendedTableRowFields
    }
    filters {
      ...FilterValueFields
    }
    lastUpdateDate
  }
  ${TableColumnFieldsFragmentDoc}
  ${GraFacilitatedPathwaysRecommendedTableRowFieldsFragmentDoc}
  ${FilterValueFieldsFragmentDoc}
`;
