import React, { useId } from 'react';

export interface ShadowTipProps {
  /**
   * The size of the shadow tip, which should match the progress bar stroke width.
   */
  size: number;
}

/**
 * This component renders a circular shadow tip placed at the end of the progress bar stroke.
 * It is designed for progress bars with `stroke-linecap="round"` and visually indicates
 * progress values exceeding 100% by highlighting the end of the stroke.
 */
const ShadowTip: React.FC<ShadowTipProps> = ({ size }) => {
  const id = useId();
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} style={{ overflow: 'visible' }}>
      <defs>
        <filter id={`${id}-shadow`} x="50%" y="-50%" width="100%" height="200%">
          <feGaussianBlur in="SourceAlpha" stdDeviation={size / 4} result="blur" />
          <feFlood floodColor="black" floodOpacity="0.8" result="color" />
          <feComposite in="color" in2="blur" operator="in" result="shadow" />
          <feComposite in="shadow" in2="SourceGraphic" operator="out" result="finalShadow" />
        </filter>
      </defs>
      <circle
        filter={`url(#${id}-shadow)`}
        cx="50%"
        cy="50%"
        r={size / 2}
        fill="black"
        stroke="none"
      />
    </svg>
  );
};

export default ShadowTip;
