import { useAnalyticsTrackEvent } from '@aily/analytics-service';
import * as T from '@aily/graphql-sdk/schema';
import React, { useCallback, useEffect, useMemo } from 'react';

import { useProFiltersContext } from '../../../../contexts';
import { useEnvironment } from '../../../../providers';
import { parseCaption } from '../../../../utils';
import { AgentSlideshowModal } from '../AgentSlideshow';

export interface AgentModalProps {
  story?: T.Story;
  open: boolean;
  parent?: string;
  onClose: () => void;
  onDecide?: () => void;
}

export const AgentModal: React.FC<AgentModalProps> = ({
  story,
  open,
  parent,
  onClose,
  onDecide,
}) => {
  const trackEvent = useAnalyticsTrackEvent();
  const proFiltersContext = useProFiltersContext();
  const { API_URL, ENV, TENANT } = useEnvironment();
  const parsedUrl = new URL(API_URL);
  const baseAudioURL = `${parsedUrl.protocol}//${ENV}-mobile-bff.${TENANT}.aily-app.com/api/file/get-image-file`;
  const agent = parseCaption(story?.caption || '{}');
  const isDecision = story?.decision;
  const agentInfo = isDecision ? agent?.imp_agent : agent?.opt_agent;

  const shouldITrackEvent = useMemo(() => {
    return open && agentInfo?.name && parent && story?.id;
  }, [open, agentInfo?.name, parent, story?.id]);

  useEffect(() => {
    if (shouldITrackEvent) {
      trackEvent('agent.seen', {
        screen_id: parent?.toString(),
        name: agentInfo?.name,
        component: 'agent',
        component_id: story?.id.toString(),
        component_type: 'agent',
        intent: 'see',
        item_type: 'agent',
        event_version: '2.1.0',
        filter_name: proFiltersContext?.selectedFilters.map((filter) => filter.label) as string[],
      });
    }
  }, [open]);

  const handleClose = useCallback(() => {
    trackEvent('agent.closed', {
      screen_id: parent?.toString() || '',
      name: agentInfo?.name ?? '',
      component: 'agent',
      component_id: story?.id.toString() ?? '',
      component_type: 'agent',
      intent: 'close',
      item_type: 'button',
      event_version: '2.1.0',
      filter_name: proFiltersContext?.selectedFilters.map((filter) => filter.label) as string[],
    });
    onClose();
  }, [story, proFiltersContext, parent, onClose, trackEvent]);

  if (!story) return null;

  return (
    <AgentSlideshowModal
      data-testid="agent-modal"
      story={story}
      open={open}
      onClose={handleClose}
      baseAudioURL={baseAudioURL}
      decision={story.decision ?? null}
      onBackdropClose={handleClose}
      onDecide={onDecide}
    />
  );
};
