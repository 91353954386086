import '@aily/saas-core/fonts';
import '@/services/graphqlService';

import { AnalyticsProvider } from '@aily/analytics-service';
import { AuthProvider } from '@aily/auth-service';
import { ErrorBoundaryProvider } from '@aily/monitoring-service';
import {
  AilyAgentProvider,
  EnvironmentProvider,
  ErrorScreen,
  ThemeProvider,
  UserSettingsProvider,
} from '@aily/saas-core';
import { ApolloProvider, client } from '@aily/saas-graphql-client';
import { AilyUIProvider } from '@aily-labs/ui';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import envConfig from '@/config/env';
import {
  ConfiguredFeatureFlagsProvider,
  EmotionProvider,
  InterceptionsProvider,
} from '@/providers';
import { analyticsConfig } from '@/services/analyticsService';

import App from './App';
import store from './store';

window.history.replaceState({}, document.title);

const root = createRoot(document.getElementById('root') as HTMLDivElement);

root.render(
  <StrictMode>
    <EnvironmentProvider value={envConfig}>
      <EmotionProvider>
        <AuthProvider>
          <AnalyticsProvider {...analyticsConfig}>
            <ConfiguredFeatureFlagsProvider>
              <StoreProvider store={store}>
                <ApolloProvider client={client}>
                  <HelmetProvider>
                    <BrowserRouter>
                      <StyledEngineProvider injectFirst>
                        <ThemeProvider>
                          <CssBaseline />
                          <UserSettingsProvider>
                            <InterceptionsProvider>
                              <SnackbarProvider maxSnack={3}>
                                <ErrorBoundaryProvider
                                  fallback={({ error }) => <ErrorScreen error={error} />}
                                >
                                  <AilyAgentProvider>
                                    <AilyUIProvider theme="default">
                                      <App />
                                    </AilyUIProvider>
                                  </AilyAgentProvider>
                                </ErrorBoundaryProvider>
                              </SnackbarProvider>
                            </InterceptionsProvider>
                          </UserSettingsProvider>
                        </ThemeProvider>
                      </StyledEngineProvider>
                    </BrowserRouter>
                  </HelmetProvider>
                </ApolloProvider>
              </StoreProvider>
            </ConfiguredFeatureFlagsProvider>
          </AnalyticsProvider>
        </AuthProvider>
      </EmotionProvider>
    </EnvironmentProvider>
  </StrictMode>,
);
