import {
  alpha,
  Box,
  BoxProps,
  Card,
  CardActionArea,
  CardContent,
  CardProps,
  Skeleton,
  styled,
  SvgIcon,
  SvgIconProps,
  Typography,
  TypographyVariants,
  useThemeProps,
} from '@mui/material';
import React from 'react';

import { lineClamp } from '../../theme/utils';

export interface RecommenderItemProps extends CardProps {
  title: string;
  titleTypography?: { [key: string]: keyof TypographyVariants };
  icon?: React.ReactElement<SvgIconProps>;
  checked?: boolean;
  coverImage?: React.ReactNode;
  BackgroundBoxProps?: Partial<BoxProps>;
  TriangularCornerProps?: Partial<BoxProps>;
  disableXxl?: boolean;
}

const RecommenderItemRoot = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'disableXxl',
})<{ disableXxl?: boolean }>(({ theme, disableXxl }) => ({
  position: 'relative',
  width: 151,
  height: 151,
  minWidth: 151,
  backgroundColor: 'transparent',

  [theme.breakpoints.up('xl')]: {
    width: 170,
    height: 170,
  },

  ...(!disableXxl && {
    [theme.breakpoints.up('xxl')]: {
      width: 220,
      height: 220,
    },
  }),

  '& .MuiCardActionArea-root': {
    position: 'absolute',
    overflow: 'hidden',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    '& img': {
      transform: 'scale(1)',
      transition: 'transform 100ms ease-out',
    },
    '&:hover img': {
      transform: 'scale(1.05)',
    },
  },

  '& .MuiCardContent-root': {
    zIndex: 1,
    width: '100%',
    padding: theme.spacing(2.5, 2, 1.625, 1.625),
  },

  '& .MuiTypography-root': {
    ...lineClamp(3),
  },

  '& .MuiCardActionArea-root + .MuiSvgIcon-root': {
    position: 'absolute',
    bottom: 13,
    left: 16,
    opacity: 0.8,
    fontSize: 32,
    pointerEvents: 'none',

    [theme.breakpoints.up('xl')]: {
      bottom: 15,
      left: 18,
      fontSize: 42,
    },

    ...(!disableXxl && {
      [theme.breakpoints.up('xxl')]: {
        bottom: 19,
        left: 24,
        fontSize: 54,
      },
    }),
  },
}));

const BackgroundBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.paper,
}));

const TriangularCorner = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'disableXxl',
})<{ disableXxl?: boolean }>(({ theme, disableXxl }) => ({
  position: 'absolute',
  right: -1,
  bottom: -1,
  width: 42,
  height: 42,
  backgroundColor: alpha(theme.palette.background.default, 0.5),
  clipPath: 'polygon(100% 0, 0% 100%, 100% 100%)',
  pointerEvents: 'none',
  color: theme.palette.text.primary,

  [theme.breakpoints.up('xl')]: {
    width: 46,
    height: 46,
  },

  ...(!disableXxl && {
    [theme.breakpoints.up('xxl')]: {
      width: 50,
      height: 50,
    },
  }),

  '& .MuiSvgIcon-root': {
    position: 'absolute',
    right: 8,
    bottom: 8,
    fontSize: 10,

    [theme.breakpoints.up('xl')]: {
      right: 9,
      bottom: 9,
      fontSize: 12,
    },

    ...(!disableXxl && {
      [theme.breakpoints.up('xxl')]: {
        right: 10,
        bottom: 10,
        fontSize: 14,
      },
    }),
  },
}));

const CheckedIcon = (props: SvgIconProps) => (
  <SvgIcon width="11" height="9" viewBox="0 0 11 9" {...props}>
    <path d="M10.7579 0.695923H9.88392C9.76139 0.695923 9.64512 0.75297 9.5701 0.850584L4.41519 7.47184L1.94339 4.29622C1.90599 4.24808 1.85833 4.20916 1.80398 4.18238C1.74963 4.15559 1.69 4.14163 1.62957 4.14156H0.75562C0.671851 4.14156 0.625591 4.23917 0.676853 4.3051L4.10137 8.70405C4.26141 8.90942 4.56898 8.90942 4.73026 8.70405L10.8366 0.85819C10.8879 0.793537 10.8416 0.695923 10.7579 0.695923Z" />
  </SvgIcon>
);

export const RecommenderItem: React.FC<RecommenderItemProps> = ({
  title,
  titleTypography,
  icon,
  checked,
  coverImage,
  BackgroundBoxProps,
  TriangularCornerProps,
  disableXxl,
  ...rest
}) => {
  const themeProps = useThemeProps({ props: { titleTypography }, name: 'RecommenderItem' });
  return (
    <RecommenderItemRoot data-testid="recommender-item" disableXxl={disableXxl} {...rest}>
      <BackgroundBox {...BackgroundBoxProps} />
      <CardActionArea component="div">
        <CardContent>
          <Typography
            sx={{
              typography: { ...themeProps.titleTypography, ...(disableXxl && { xxl: undefined }) },
            }}
            data-testid="title"
          >
            {title}
          </Typography>
        </CardContent>
        {coverImage}
      </CardActionArea>
      {icon}
      {!!checked && (
        <TriangularCorner disableXxl={disableXxl} {...TriangularCornerProps}>
          <CheckedIcon
            sx={{ fontSize: { xs: 10, xl: 12, xxl: !disableXxl ? 15 : undefined } }}
            data-testid="checked-icon"
          />
        </TriangularCorner>
      )}
    </RecommenderItemRoot>
  );
};

export const RecommenderItemSkeleton = ({
  index = 0,
  disableAnimation,
  disableXxl,
}: {
  index?: number;
  disableAnimation?: boolean;
  disableXxl?: boolean;
}) => (
  <RecommenderItemRoot
    disableXxl={disableXxl}
    sx={{
      background: 'transparent',
      boxShadow: 'none',
      '& .MuiSkeleton-root': { height: '100%', animationDelay: `${index * 0.1}s !important` },
    }}
  >
    <Skeleton variant="rectangular" animation={disableAnimation ? false : 'pulse'} />
  </RecommenderItemRoot>
);
