import { EventTrackingConfig, getPathDetails, withEventTracking } from '@aily/analytics-service';
import { GetPlailistStoriesQuery, useGetPlailistStoriesLazyQuery } from '@aily/graphql-sdk/core';
import * as T from '@aily/graphql-sdk/schema';
import {
  Alert,
  alpha,
  Button,
  ButtonProps,
  Container,
  List,
  ListItemButton,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { unionBy } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ChevronRight } from '../../icons';
import { colors } from '../../theme/default/colors';
import { mapColorUnionToColor, mapSentimentToColor } from '../../utils';
import { ClosableModal, ClosableModalProps } from '../ClosableModal';
import { LoadingSkeleton } from '../LoadingSkeleton';
import StorySliderModal, { StorySliderModalProps } from '../StorySlider/StorySliderModal';
import PlailistFilter from './PlailistFilter';

export interface PlailistModalProps extends Omit<ClosableModalProps, 'children'> {
  plailist?: T.Plailist;
  defaultSelectedIndex?: number;
  onClose?: () => void;
  onStoryClick?: (story: T.Story, index: number) => void;
  onStorySliderChange?: (story: T.Story, index: number) => void;
  onStorySliderClose?: () => void;
  onPlailistInit?: (plailist: T.Plailist) => void;
  onAutoPlay?: (plailist: T.Plailist) => void;
  StorySliderModalProps?: Partial<StorySliderModalProps>;
}

const SentimentIndicator = styled('span')(({ theme }) => ({
  display: 'inline-block',
  width: 20,
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(-1),
  textAlign: 'center',
}));

interface PlaylistSentimentIndicatorProps {
  sentiment: T.Sentiment;
}

const PlaylistSentimentIndicator: React.FC<PlaylistSentimentIndicatorProps> = ({ sentiment }) => {
  const color = mapSentimentToColor(sentiment, {
    [T.Sentiment.Neutral]: colors.primary.aqua,
  });

  switch (sentiment) {
    case T.Sentiment.SlightlyPositive:
    case T.Sentiment.Positive:
    case T.Sentiment.VeryPositive:
      return <SentimentIndicator style={{ color }}>▲</SentimentIndicator>;
    case T.Sentiment.SlightlyNegative:
    case T.Sentiment.Negative:
    case T.Sentiment.VeryNegative:
      return <SentimentIndicator style={{ color }}>▼</SentimentIndicator>;
    default:
      return <SentimentIndicator style={{ color, fontSize: 8 }}>●</SentimentIndicator>;
  }
};

const PlayButton = styled(({ children, ...rest }: ButtonProps) => (
  <Button variant="contained" {...rest}>
    <Typography variant="subtitle">{children}</Typography>
  </Button>
))(() => ({
  width: 136,
  height: 36,
  borderRadius: 66,
  color: colors.neutrals.black,
  backgroundColor: colors.neutrals.white,
  '&:hover': {
    backgroundColor: colors.neutrals.white,
  },
}));

const SkeletonWrapper = styled('div')(() => ({ width: 650 }));

const StyledList = styled(List)(({ theme }) => ({
  width: 650,
  height: 'calc(100vh - 450px)',
  overflow: 'auto',
  '& .MuiListItemButton-root': {
    minHeight: 48,
    borderBottom: `1px solid ${alpha(colors.neutrals.white, 0.15)}`,
    '&:first-of-type': {
      borderTop: `1px solid ${alpha(colors.neutrals.white, 0.15)}`,
    },
    paddingRight: theme.spacing(2.5),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const PlailistModal: React.FC<PlailistModalProps> = ({
  plailist,
  defaultSelectedIndex,
  onClose,
  onStoryClick,
  onStorySliderChange,
  onStorySliderClose,
  onPlailistInit,
  onAutoPlay,
  open,
  StorySliderModalProps,
  ...rest
}) => {
  const [selectedIndex, setSelectedIndex] = useState(defaultSelectedIndex);
  const [isPlaying, setIsPlaying] = useState(false);
  const [filters, setFilters] = useState<T.PlailistFilterInput[]>([]);
  const isAnyFilterChangedRef = useRef<boolean>(false);
  const [stories, setStories] = useState<T.Story[]>((plailist?.stories ?? []) as T.Story[]);
  const theme = useTheme();

  const onCompleted = useCallback((data: GetPlailistStoriesQuery) => {
    setStories(data?.storiesByPlailist as T.Story[]);
  }, []);

  const [getStoriesByPlailist, { loading: storiesByPlailistLoading }] =
    useGetPlailistStoriesLazyQuery({
      onCompleted,
    });

  const contentBackground = useMemo(() => {
    if (stories.some((story) => story.storyType === T.StoryDisplayType.WhisperReal)) {
      return colors.neutrals.black;
    }

    return theme.palette.background.default;
  }, [stories, theme]);

  useEffect(() => {
    if (plailist) {
      onPlailistInit?.(plailist);
    }
  }, [plailist, onPlailistInit]);

  const handleStoryClick = useCallback(
    (story: T.Story, index: number) => () => {
      setIsPlaying(false);
      setSelectedIndex(index);
      onStoryClick?.(story, index);
    },
    [onStoryClick],
  );

  const handleStorySliderClose = useCallback(() => {
    setSelectedIndex(undefined);
    onStorySliderClose?.();
  }, [onStorySliderClose]);

  const handleAutoPlay = useCallback(
    (plailist: T.Plailist) => () => {
      setIsPlaying(true);
      setSelectedIndex(0);
      onAutoPlay?.(plailist);
    },
    [onAutoPlay],
  );

  const handleFilterChange = useCallback((filterCode?: string, value?: number) => {
    const filterInput = { filterCode, value } as T.PlailistFilterInput;

    setFilters((prevState) => unionBy([filterInput], prevState, 'filterCode'));

    isAnyFilterChangedRef.current = true;
  }, []);

  const handleClose = useCallback(() => {
    setFilters([]);
    setStories([]);
    setIsPlaying(false);
    setSelectedIndex(undefined);
    isAnyFilterChangedRef.current = false;
    onClose?.();
  }, [onClose]);

  useEffect(() => {
    if (plailist?.plailistId) {
      getStoriesByPlailist({
        variables: {
          input: {
            plailistId: plailist?.plailistId,
            filters: (filters?.filter((filter) => filter) as T.PlailistFilterInput[]) ?? [],
          },
        },
      });
    }
  }, [plailist, filters, getStoriesByPlailist]);

  return (
    <>
      <ClosableModal
        open={open}
        onClose={handleClose}
        ContentBackgroundProps={{ sx: { background: contentBackground } }}
        {...rest}
      >
        <>
          {!!plailist && (
            <Stack
              sx={{
                pt: 10,
                '&::before, &::after': {
                  content: '""',
                  position: 'fixed',
                  zIndex: -1,
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: 350,
                },
                '&::before': {
                  background:
                    plailist.colorStart && plailist.colorEnd
                      ? `linear-gradient(180deg,
                            ${mapColorUnionToColor(plailist.colorStart)} 0%,
                            ${mapColorUnionToColor(plailist.colorEnd)} 65%
                          )`
                      : `linear-gradient(180deg,
                            ${colors.primary.aqua} 0%,
                            ${colors.primary.aqua} 65%
                          )`,
                },
                '&::after': {
                  background: `linear-gradient(0deg,
                      ${contentBackground} 0%,
                      ${alpha(contentBackground, 0)} 100%
                    )`,
                },
              }}
            >
              <Container maxWidth="xl">
                <Stack direction="column" spacing={7.5} alignItems="center" sx={{ mb: 6 }}>
                  <Typography variant="h2">{plailist.title}</Typography>
                  {!!plailist.summary && <Typography variant="h7">{plailist.summary}</Typography>}
                </Stack>
                <Stack direction="column" spacing={7.5} alignItems="center">
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={10}
                  >
                    {!!stories.length && (
                      <PlayButton onClick={handleAutoPlay(plailist)}>Play</PlayButton>
                    )}
                    {plailist.filters?.map((filter, filterIndex) => (
                      <PlailistFilter
                        key={filterIndex}
                        filter={filter}
                        onFilterChange={handleFilterChange}
                      />
                    ))}
                  </Stack>
                  {storiesByPlailistLoading ? (
                    <SkeletonWrapper>
                      <LoadingSkeleton />
                    </SkeletonWrapper>
                  ) : (
                    <>
                      {stories.length ? (
                        <StyledList>
                          {stories.map((story, index) => (
                            <ListItemButton key={story.id} onClick={handleStoryClick(story, index)}>
                              <PlaylistSentimentIndicator
                                sentiment={story.sentiment ?? T.Sentiment.Neutral}
                              />
                              <Typography variant="bodyBold" sx={{ mr: 'auto' }}>
                                {story.headline}
                              </Typography>
                              <ChevronRight sx={{ fontSize: 16 }} />
                            </ListItemButton>
                          ))}
                        </StyledList>
                      ) : (
                        <Alert variant="outlined" severity="info">
                          No stories available
                        </Alert>
                      )}
                    </>
                  )}
                </Stack>
              </Container>
            </Stack>
          )}
        </>
      </ClosableModal>
      <StorySliderModal
        stories={stories}
        defaultSlide={selectedIndex}
        open={open && selectedIndex !== undefined}
        onClose={handleStorySliderClose}
        onCurrentSlideChange={onStorySliderChange}
        isPlaying={isPlaying}
        {...StorySliderModalProps}
      />
    </>
  );
};

const trackingConfig: EventTrackingConfig<PlailistModalProps> = {
  onPlailistInit: {
    eventName: 'plailist.seen',
    getEventProps: (plailist) => ({
      component: 'plailist',
      name: plailist.title || '',
      component_id: plailist.plailistId || '',
      intent: 'see',
      item_type: 'plailist',
      event_version: '2.0.0',
    }),
  },
  onStoryClick: {
    eventName: 'story.seen',
    getEventProps: (story) => {
      const { focusArea, focusAreaPath } = getPathDetails(story.link?.absolutePath ?? '');

      return {
        component: 'story',
        intent: 'see',
        item_type: 'story',
        component_id: story.id,
        component_type: story.storyType,
        event_version: '2.1.0',
        name: story.headline,
        focus_area: focusArea,
        focus_area_path: focusAreaPath,
      };
    },
  },
  onAutoPlay: {
    eventName: 'plailist.played',
    getEventProps: (plailist) => ({
      component: 'plailist',
      name: plailist?.title || '',
      component_id: plailist?.plailistId?.toString() || '',
      intent: 'play',
      item_type: 'button',
      event_version: '2.0.0',
    }),
  },
};

export const TrackedPlailistModal = withEventTracking(PlailistModal, trackingConfig);

export default PlailistModal;
