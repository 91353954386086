export const parseCaption = (caption: string) => {
  let parsedCaption;

  try {
    parsedCaption = JSON.parse(caption || '{}');
  } catch (e) {
    parsedCaption = caption || '';
  }

  return parsedCaption;
};
