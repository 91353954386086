import { alpha, Box, Stack, styled, Toolbar, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useRef } from 'react';

import { useOverflow } from '../../hooks';
import { RecommenderItem, RecommenderItemProps } from './RecommenderItem';

export interface RecommenderListProps {
  title?: string;
  toolbarAddons?: React.ReactNode;
  disableAnimation?: boolean;
  children?: React.ReactElement<RecommenderItemProps> | React.ReactElement<RecommenderItemProps>[];
}

const StyledStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'overflowX',
})<{ overflowX?: number }>(({ theme, overflowX }) => ({
  position: 'relative',
  overflow: 'hidden',
  '& .MuiCard-root': {
    flexShrink: 0,
  },
  ...(overflowX && {
    '&::after': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      top: 0,
      right: 0,
      width: '10%',
      height: '100%',
      background: `linear-gradient(
        90deg,
        ${alpha(theme.palette.background.default, 0)},
        ${theme.palette.background.default}
      )`,
    },
  }),
}));

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
}));

export const RecommenderList: React.FC<RecommenderListProps> & { Item: typeof RecommenderItem } = ({
  title = 'My recommender',
  toolbarAddons,
  disableAnimation,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { x: overflowX } = useOverflow(ref);

  return (
    <StyledBox data-testid="recommender">
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h9" data-testid="recommender-title">
          {title}
        </Typography>
        {toolbarAddons}
      </Toolbar>
      <StyledStack ref={ref} direction="row" spacing={2} overflowX={overflowX}>
        {disableAnimation
          ? children
          : React.Children.map(children, (child, index) => (
              <motion.div
                key={index}
                custom={index}
                animate="visible"
                initial={{ opacity: 0 }}
                variants={{
                  visible: (index: number) => ({
                    opacity: 1,
                    transition: { delay: index * 0.02, duration: 0.1 },
                  }),
                }}
                layout
              >
                {child}
              </motion.div>
            ))}
      </StyledStack>
    </StyledBox>
  );
};

RecommenderList.Item = RecommenderItem;
