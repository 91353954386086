import { motion, Transition } from 'framer-motion';
import React, { SVGProps, useId } from 'react';

import { isLinearGradient } from '../../../utils';
import GlowFilter, { GlowFilterProps } from './GlowFilter';
import ProgressBarGradient from './ProgressBarGradient';
import ShadowTip from './ShadowTip';
import useCircularProgressBarAnimation from './useCircularProgressBarAnimation';

export interface CircularProgressBarProps extends React.ComponentProps<'svg'> {
  /**
   * Current value of the progress bar, between 0 and 1.
   */
  value: number;
  /**
   * Diameter of the progress bar (in pixels).
   */
  size: number;
  /**
   * Width of the progress bar stroke.
   */
  strokeWidth: number;
  /**
   * Solid color or linear-gradient for the progress bar.
   */
  progressColor: string;
  /**
   * Color of the track circle.
   */
  trackColor?: string;
  /**
   * Optional background color inside the track circle.
   */
  backgroundColor?: string;
  /**
   * Whether to show the glow effect for the progress bar stroke.
   */
  glow?: boolean;
  /**
   * Optional props to customize the glow filter.
   */
  GlowFilterProps?: Partial<GlowFilterProps>;
  /**
   * Whether to show the shadow tip at the end of the progress bar stroke.
   */
  shadowTip?: boolean;
  /**
   * Animation transition settings.
   */
  transition?: Transition;
  /**
   * Target value for displaying a dot.
   */
  targetValue?: number;
  /**
   * Size of the target dot.
   */
  targetSize?: number;
  /**
   * Color of the target dot.
   */
  targetColor?: string;
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
}

const defaultTransition: Transition = { duration: 0.5, ease: 'easeInOut' };

/**
 * This component represents a circular progress bar and serves as a reusable base for specific implementations like the `Gauge` component.
 */
const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
  value,
  size,
  strokeWidth,
  progressColor,
  trackColor,
  backgroundColor,
  glow,
  GlowFilterProps,
  shadowTip,
  transition = defaultTransition,
  targetValue,
  targetSize = 14,
  targetColor,
  children,
  ...rest
}) => {
  const id = useId();
  const isGradient = progressColor && isLinearGradient(progressColor);
  const { pathLengthControls, rotationControls } = useCircularProgressBarAnimation({ value });

  // Shared circle props
  const circleProps = {
    cx: '50%',
    cy: '50%',
    r: size / 2 - strokeWidth / 2,
    strokeWidth,
    strokeLinecap: 'round',
    fill: 'none',
  } satisfies SVGProps<SVGCircleElement>;

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      style={{ overflow: 'visible' }}
      {...rest}
    >
      {/* Gradient and Glow Filter Definitions */}
      {isGradient && <ProgressBarGradient id={`${id}-gradient`} linearGradient={progressColor!} />}
      {glow && (
        <GlowFilter id={`${id}-glow`} size={size} strokeWidth={strokeWidth} {...GlowFilterProps} />
      )}

      {/* Track */}
      {trackColor && <circle stroke={trackColor} {...circleProps} />}

      {/* Progress Bar */}
      <g style={{ transform: 'rotate(-90deg)', transformOrigin: 'center' }}>
        <motion.circle
          stroke={isGradient ? `url(#${id}-gradient)` : progressColor}
          filter={glow ? `url(#${id}-glow)` : undefined}
          initial={{ pathLength: 0 }}
          animate={pathLengthControls}
          transition={transition}
          {...circleProps}
        />
      </g>

      {/* Shadow Tip */}
      {shadowTip && (
        <motion.g
          style={{ originX: `${strokeWidth / 2}px`, originY: '50%' }}
          transformTemplate={({ rotate }) =>
            `translateX(calc(50% - ${strokeWidth / 2}px)) rotate(${rotate})`
          }
          initial={{ rotate: 0 }}
          animate={rotationControls}
          transition={transition}
        >
          <ShadowTip size={strokeWidth} />
        </motion.g>
      )}

      {/* Background Color */}
      {backgroundColor && (
        <rect
          x={strokeWidth}
          y={strokeWidth}
          width={size - strokeWidth * 2}
          height={size - strokeWidth * 2}
          rx="50%"
          ry="50%"
          fill={backgroundColor}
        />
      )}

      {/* Target Dot */}
      {targetValue !== undefined && (
        <circle
          r={targetSize / 2}
          cx={size / 2}
          cy={strokeWidth / 2}
          fill={targetColor}
          style={{ transform: `rotate(${targetValue * 360}deg)`, transformOrigin: 'center' }}
        />
      )}

      {children}
    </svg>
  );
};

export default CircularProgressBar;
