import * as T from '@aily/graphql-sdk/schema';
import { Box } from '@mui/material';
import React from 'react';

import { KpiStatusOverview } from '../KpiStatusOverview';
import { RiskIndicator } from '../RiskIndicator';
import { TimelinePhases } from '../TimelinePhases';
import { TableCellActualWithVariance } from './TableCellActualWithVariance';
import { TableCellBadge } from './TableCellBadge';
import { TableCellComparatorTrend } from './TableCellComparatorTrend';
import { TableCellCriteriaBadgeResult } from './TableCellCriteriaBadgeResult';
import { TableCellIconLink } from './TableCellIconLink';
import { TableCellMicroChart } from './TableCellMicroChart';
import { TableCellTextResult } from './TableCellTextResult';
import { TableCellTimeline } from './TableCellTimeline';
import { TableCellVariance } from './TableCellVariance';

interface TableCellContentProps {
  value?: T.TableCellContent;
  rowName?: string;
  sentimentColorMap?: Partial<Record<T.Sentiment, string>>;
}

const emptyArray: never[] = [];

export const TableCellContent: React.FC<TableCellContentProps> = ({
  value,
  rowName,
  sentimentColorMap,
}) => {
  if (T.isMicroChartResult(value)) {
    return <TableCellMicroChart microChart={value} />;
  }

  if (T.isVarianceResult(value)) {
    return <TableCellVariance variance={value} sentimentColorMap={sentimentColorMap} />;
  }

  if (T.isKpiStatusOverview(value)) {
    return (
      <KpiStatusOverview
        items={value.items ?? emptyArray}
        tooltip={value.tooltip ?? undefined}
        compact
      />
    );
  }

  if (T.isTimelinePhases(value)) {
    return <TimelinePhases axis={value.axis} intervals={value.intervals ?? emptyArray} />;
  }

  if (T.isValueWithVarianceResult(value)) {
    return <TableCellActualWithVariance actualWithVariance={value} />;
  }

  if (T.isTimeline(value)) {
    return <TableCellTimeline timeline={value} name={rowName} />;
  }

  if (T.isTextResult(value)) {
    return <TableCellTextResult value={value} />;
  }

  if (T.isRiskIndicatorResult(value)) {
    const { actual, previousSentiment, sentiment, target } = value;
    return (
      <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
        <RiskIndicator
          actual={actual ?? undefined}
          previousSentiment={previousSentiment ?? undefined}
          sentiment={sentiment ?? undefined}
          target={target ?? undefined}
        />
      </Box>
    );
  }

  if (T.isBadgeResult(value)) {
    return <TableCellBadge badge={value} />;
  }

  if (T.isCriteriaBadgeResult(value)) {
    return <TableCellCriteriaBadgeResult badge={value} />;
  }

  if (T.isIconLinkResult(value)) {
    return <TableCellIconLink iconLink={value} />;
  }

  if (T.isComparatorTrendResult(value)) {
    return <TableCellComparatorTrend value={value} />;
  }

  return null;
};
