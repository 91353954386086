import * as T from '@aily/graphql-sdk/schema';

export type SortableFilterOptionResult = T.FilterOptionResult & {
  sortOrder: number;
  subLabel: string;
};

export type FilterOption = T.FilterOptionResult | T.TreeFilterOptionResult;

export type DimensionFilter = T.SelectFilter | T.TreeSelectFilter;

export const isTreeFilterOptionResult = (value?: FilterOption): value is T.TreeFilterOptionResult =>
  value?.__typename === 'TreeFilterOptionResult';

// Type guard for ItemSubLabel since subLabel is added on PriorityResult type but not on FilterOptionResult and TreeFilterOptionResult
export function isSortableFilterOption(item: FilterOption): item is SortableFilterOptionResult {
  return 'subLabel' in item;
}
